<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/bienvenida" text=""></ion-back-button>
        </ion-buttons>
        <ion-title>
          <ion-img
            src="assets/logo-header-toolbar.svg"
            class="img-toolbar"
          ></ion-img>
        </ion-title>
      </ion-toolbar> </ion-header
    >`
    <ion-content>
      <div class="ion-text-center max-widt-mobileh">
        <h4>{{ $t("Successfully logged out") }}</h4>
        <ion-button href="/welcome" expand="block" size="medium">{{
          $t("Back")
        }}</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonImg
} from "@ionic/vue";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "Login",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonImg
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    onMounted(() => {
      store.dispatch("auth/logout").then(() => {
        router.push({ path: "/welcome", replace: true });
      });
    });
    return {};
  }
};
</script>
<style scoped>
.img-toolbar {
  width: auto;
  margin: auto;
  height: 45px;
}
.password + ion-icon {
  display: none;
}
.password.has-focus + ion-icon,
.password.has-value + ion-icon {
  display: block;
}
.password + ion-icon {
  align-self: flex-end;
}
</style>
